import {useContext, useLayoutEffect} from "react";
import {Form, Icon, MultiField, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {FormInstance, FormItemProps} from "antd";
import _ from "lodash";
import "./style.scss";
import PatternFormat from "react-number-format";
// core
import {LayoutContext, ModalContext} from "../../../core/Contexts";
import ClipBoardErrorModal from "./errorModal";

export default function SimField({
    props,
    prefix,
    formInstance,
    hasSimValue,
    setHasSimValue
}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const {t} = useTranslation();

    const onCancel = () => {
        contextModal?.setOnCancel(() => formInstance?.resetFields());
        contextModal?.setVisible(false);
    };
    useLayoutEffect(() => {
        const target: any = document.querySelector("div.bl-field-sim");
        if (target) {
            target.addEventListener("paste", (event: any) => {
                event.stopPropagation();
                const text = (event.clipboardData || (window as any).clipboardData).getData("text");

                if (
                    !_.isNaN(+text) &&
                    ((text.length === 18 && /^8970199.*/gi.test(text)) || text.length === 11)
                ) {
                    event.preventDefault();
                    let sim = text;

                    if (text.length === 18) {
                        sim = text.slice(-11);
                    }
                    formInstance?.setFieldValue("sim", sim);
                    if (setHasSimValue) {
                        setHasSimValue(true);
                    }

                    // formInstance?.setFieldValue("sim", _.split(sim, ""));
                    // _.chain(_.split(sim, ""))
                    //     .forEach((i, k) => {
                    //         formInstance?.setFieldValue(`sim${k}`, i);
                    //     })
                    //     .valueOf();
                } else {
                    contextModal?.setVisible(true);
                    contextModal?.setChildren(<ClipBoardErrorModal />);
                    contextModal?.setOnCancel(() => onCancel);
                }
            });
        }
        if (
            !_.isEqual(formInstance?.getFieldValue("sim"), "") &&
            !_.isEqual(formInstance?.getFieldValue("sim"), undefined)
        ) {
            if (setHasSimValue) {
                setHasSimValue(true);
            }
        }
    }, []);

    return (
        <Typography as="div" className="bl-field-sim">
            <Space direction="vertical" size={16}>
                <Typography as="div" className="header">
                    <Space
                        direction="vertical"
                        size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 0}
                    >
                        <Typography as="div" color="#fff" size={24} className="title">
                            {t("Номер SIM-карты")}
                        </Typography>
                        <Typography as="div" color="#fff" size={14} className="subtitle">
                            {t("При необходимости Вы можете")}
                            <br />
                            {t("сменить номер SIM-карты")}
                        </Typography>
                    </Space>
                </Typography>
                <Typography as="div" className="content">
                    <Space
                        direction="vertical"
                        size={_.gte(contextLayout?.width ?? 0, 768) ? 80 : 60}
                        align="start"
                    >
                        <Icon
                            name="logoText"
                            size={_.gte(contextLayout?.width ?? 0, 768) ? "55" : "44"}
                            className="logo-text"
                        />
                        <Typography as="div" className="binding-space-container">
                            <Space direction="vertical" size={16} align="center">
                                <Typography as="div" className="sim-container">
                                    <Form.Item noStyle shouldUpdate>
                                        <Typography
                                            as="span"
                                            className="sim-text"
                                            fontFamily="alumna"
                                            size={_.gte(contextLayout?.width ?? 0, 768) ? 32 : 26}
                                        >
                                            {prefix}
                                        </Typography>
                                    </Form.Item>

                                    {_.isNil(formInstance) ? (
                                        <MultiField
                                            form={formInstance!}
                                            length={11}
                                            pattern={/^(\d)$/gi}
                                            inputProps={{inputMode: "numeric"}}
                                            placeholder={"_"}
                                        />
                                    ) : (
                                        <Typography
                                            as="div"
                                            className={`field-sim ${hasSimValue ? "" : "no-value"}`}
                                        >
                                            <Form.Item
                                                {...props}
                                                className="field-sim"
                                                name="sim"
                                                // normalize={(value) => {
                                                //     // return _.slice(value, 0, 11);
                                                // }}
                                            >
                                                {/* <Input.Number
                                                count={11}
                                                type={"number"}
                                                inputMode={"numeric"}
                                                pattern={"[0-9]*"}
                                                autoComplete={"off"}
                                                placeholder="_"
                                            /> */}
                                                <PatternFormat
                                                    // customInput={Input}
                                                    format="###########"
                                                    mask="_"
                                                />
                                            </Form.Item>
                                        </Typography>
                                    )}
                                </Typography>
                                <Form.Item noStyle shouldUpdate>
                                    {({getFieldsValue}) => {
                                        const {multi} = getFieldsValue();
                                        const {sim} = getFieldsValue();
                                        return (
                                            <Typography as="div" className="binding-container">
                                                <Space direction="vertical" size={0}>
                                                    <Typography
                                                        as="div"
                                                        className="binding"
                                                        size={
                                                            _.gte(contextLayout?.width ?? 0, 768)
                                                                ? 13
                                                                : 10
                                                        }
                                                        color="rgba(255, 255, 255, 0.4)"
                                                    >
                                                        {prefix}
                                                        {sim?.replace(/\_/gm, "")}
                                                        {/* {_.chain(multi)
                                                            .filter(
                                                                (i) =>
                                                                    !_.isNil(i) && _.size(_.trim(i))
                                                            )
                                                            .join("")
                                                            .valueOf()} */}
                                                    </Typography>
                                                    <Icon
                                                        name="code"
                                                        size={
                                                            _.gte(contextLayout?.width ?? 0, 768)
                                                                ? "146"
                                                                : "107"
                                                        }
                                                        className="code"
                                                    />
                                                </Space>
                                            </Typography>
                                        );
                                    }}
                                </Form.Item>
                            </Space>
                        </Typography>
                    </Space>
                </Typography>
            </Space>
        </Typography>
    );
}

interface IProps {
    props: FormItemProps<any>;
    prefix: string;
    formInstance?: FormInstance<any>;
    hasSimValue?: boolean;
    setHasSimValue?: React.Dispatch<React.SetStateAction<boolean>>;
}

import React, {CSSProperties} from "react";

export const LayoutContext = React.createContext<ILayoutContext | null>(null);
export const InterceptorContext = React.createContext<IInterceptorContext | null>(null);
export const ModalContext = React.createContext<IModalContext | null>(null);
export const UserContext = React.createContext<IUserContext | null>(null);
export const LoadingContext = React.createContext<{
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);
export const SessionContext = React.createContext<ISessionContext | null>(null);

interface IInterceptorContext {
    status: number;
    request?: any;
    data?: any;
    loading?: boolean;
    loadingBtn?: boolean;
    loadingBlock?: boolean;
    setErrors: React.Dispatch<any>;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    // source: CancelTokenSource | undefined;
}

interface ILayoutContext {
    width: number | undefined;
}

export interface IModalContext {
    visible: boolean;
    id?: number;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setChildren: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>;
    setOnCancel: React.Dispatch<React.SetStateAction<() => void>>;
    setCssClass: React.Dispatch<React.SetStateAction<string>>;
    setMaskStyle: React.Dispatch<React.SetStateAction<CSSProperties | undefined>>;
    setId: React.Dispatch<React.SetStateAction<number>>;
    setMaskClosable: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUserContext {
    email?: string;
    phone?: string;
    setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface ISessionContext {
    toggleCollapsed: () => void;
}

import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// forms
import RemoveEsimForm from "../../forms/removeEsim";
// core
import {LayoutContext} from "../../../core/Contexts";
//images
import SuccessImg from "../../../images/remove_esim.png";

export default React.memo(
    observer(function CancelReservationEsim({id, tariffId, phone_number}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-remove-booking-modal">
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 48 : 48}
                    direction="vertical"
                    align="center"
                >
                    <img src={SuccessImg} alt={"star"} height={100} />
                    <Typography as="div" className="title">
                        {" Отменить \nвыпуск eSIM?"}
                    </Typography>
                    <RemoveEsimForm id={id} tariffId={tariffId} phone_number={phone_number} />
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    id: any;
    tariffId: string | undefined;
    phone_number: string | undefined;
}

import React, {useCallback, useContext, useEffect, useState} from "react";
import {Typography, Space, Button, Form} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// forms
import SimForm from "../../forms/sim";
// core
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
// services
import ReservationsService from "../../../services/ReservationsService";
// components
import ErrorModal from "../error";
import SuccessImg from "../../../images/gal.png";
import closeImg from "../../../images/close.png";

export default React.memo(
    observer(function SimModal({phone}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const context = useContext(InterceptorContext);
        const [form] = Form.useForm();
        const {t} = useTranslation();
        const [message, setMessage] = useState<string>();

        const onCan = () => {
            contextModal?.setVisible(false);
            context?.setErrors(undefined);
            form.resetFields();
            contextModal?.setCssClass("");

            if (context?.setLoading) {
                context?.setLoading(true);
            }
            window.location.reload();
        };

        const postEasyDeliverySim = useCallback(async (phone: number) => {
            try {
                const {result, message} = await ReservationsService.postEasyDeliverySim(phone);

                if (!result) {
                    throw new Error(message);
                }

                setMessage(message);
            } catch (e: any) {
                contextModal?.setChildren(<ErrorModal subtitle={e.message} />);
            }
        }, []);

        useEffect(() => {
            postEasyDeliverySim(phone);
        }, []);

        return (
            <Typography as="div" className="bl-sim-modal">
                <Button
                    icon={<img src={closeImg} alt="" />}
                    className="close-btn"
                    onClick={onCan}
                ></Button>
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 48 : 48}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 48 : 16}
                            direction="vertical"
                            align="center"
                        >
                            <img src={SuccessImg} alt={"star"} height={100} />
                            <Typography
                                className="title"
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 32 : 32}
                                color="#fff"
                            >
                                {t("Выдача SIM в Билайн")}
                            </Typography>
                            {message ? (
                                <Typography
                                    color="#fff"
                                    size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 18}
                                    className="subtitle"
                                >
                                    {t(message)}
                                </Typography>
                            ) : null}
                        </Space>
                    </Typography>
                    <SimForm phone={phone} message={message} />
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    phone: any;
}

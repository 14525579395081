import {makeAutoObservable} from "mobx";

class Filters {
    [Symbol.toStringTag] = this.constructor.name;
    private _open: boolean = false;
    private _sevise: any;

    constructor() {
        makeAutoObservable(this);
    }

    get sevise() {
        return this._sevise;
    }

    set sevise(value: any) {
        this._sevise = value;
    }

    get open() {
        return this._open;
    }

    set open(value: boolean) {
        this._open = value;
    }
}

export default Filters;

import {useState, useEffect, useRef} from "react";
import axios, {AxiosRequestConfig, AxiosResponse, Canceler} from "axios";
import {observer} from "mobx-react";
import {serialize} from "object-to-formdata";
import _ from "lodash";
// store
import Store from "../stores";
// core
import Axios, {IAxiosConfig} from "./Axios";
import {InterceptorContext} from "./Contexts";
// ui
import Loading from "../components/blocks/ui/loading";
import UserService from "../services/UserService";
import {useNavigate} from "react-router-dom";

export default observer(function Interceptor({children}: IProps) {
    const timeout: any = useRef();
    const reqInterceptor = useRef(0);
    const resInterceptor = useRef(0);
    const pendingRequests = useRef(new Map());
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loadingBlock, setLoadingBlock] = useState(false);
    const [errors, setErrors] = useState<any | null>(null);
    const navigate = useNavigate();

    reqInterceptor.current = Axios.interceptors.request.use(
        async (req: AxiosRequestConfig): Promise<AxiosRequestConfig | IAxiosConfig> => {
            setErrors(null);
            setLoading((req as IAxiosConfig).loading!);
            setLoadingBtn((req as IAxiosConfig).loadingBtn!);
            setLoadingBlock((req as IAxiosConfig).loadingBlock!);
            const source = axios.CancelToken.source();
            // setSource(source);
            const requestId = `${req.method}_${req.baseURL}_${req.url}_${_.map(
                req.params,
                (value, key) => `${key}=${value}`
            ).join("&")}`;
            const cancelToken = source.token;
            req.headers = {...req.headers, Authorization: process.env.REACT_APP_BASIC_AUTH_HEADER!};

            if (!_.isEmpty(req.data)) {
                if (req.method === "post") {
                    req.data = serialize(req.data);
                } else if (req.method === "put" && _.isObject(req.data)) {
                    req.headers["Content-Type"] = "application/x-www-form-urlencoded";
                    req.data = new URLSearchParams(req.data as any).toString();
                }
            }

            if (Store.user.token) {
                req.headers["Api-token"] = Store.user.token;
            }

            if (pendingRequests.current.has(requestId)) {
                pendingRequests.current.get(requestId)();
                pendingRequests.current.delete(requestId);
            }

            addRequest(requestId, source.cancel);
            return {...req, cancelToken, requestId};
        }
    );

    resInterceptor.current = Axios.interceptors.response.use(
        async (res: AxiosResponse<any>): Promise<AxiosResponse<any>> => {
            const {requestId} = res.config as IAxiosConfig;

            if (requestId) {
                cancelRequest(requestId);
            }

            return res;
        },
        async (e) => {
            cancelAllRequests();
            setErrors(e.response);

            if (_.isEqual(e?.response?.status, 401)) {
                UserService.postLogout();
                navigate("/");
            }

            return Promise.reject(e);
        }
    );

    const addRequest = (requestId: string, fn: Canceler): void => {
        cancelRequest(requestId);
        pendingRequests.current.set(requestId, fn);
    };

    const removeRequest = (requestId: string): void => {
        pendingRequests.current.delete(requestId);
    };

    const cancelRequest = (requestId: string): void => {
        if (pendingRequests.current.has(requestId)) {
            pendingRequests.current.get(requestId)();
            removeRequest(requestId);

            if (!pendingRequests.current.size) {
                clearTimeout(timeout.current);
                setLoadingBtn(false);
                setLoadingBlock(false);
                timeout.current = setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        }
    };

    const cancelAllRequests = (): void => {
        pendingRequests.current.clear();
        setLoading(false);
        setLoadingBtn(false);
        setLoadingBlock(false);
    };

    useEffect(() => {
        return () => {
            Axios.interceptors.request.eject(reqInterceptor.current);
            Axios.interceptors.response.eject(resInterceptor.current);
        };
    });

    return (
        <InterceptorContext.Provider
            value={{...errors, loading, loadingBtn, loadingBlock, setErrors, setLoading}}
        >
            <div className={loading ? "" : "d-none"}>
                <Loading />
            </div>
            {children}
        </InterceptorContext.Provider>
    );
});

interface IProps {
    children: JSX.Element;
}
